.menu{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    padding: 1rem 0;
}

@media(max-width:1000px){
    .menu{
    grid-template-columns: 1fr 1fr;}
}
.top, .bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.--red-border{
    border: 1px solid red;
}

.left, .right{
    display: flex;
    align-items: flex-end;
    gap: 1rem;
}

 .menu-header .top{
    font-size: 0.9rem;
 }

.nav-links{
    display: flex;

 }

ul li:first-child{
    list-style: none;
}
.auth-form-container,
.login-form,
.register-form {
  display: flex;
  flex-direction: column;
}

.auth-form-container {
  background: #ffffff;
  /* background: -webkit-linear-gradient(to right,#b9ebff, #F7F8F8);  
    background: linear-gradient(to right, #f5fafa,  #F7F8F8);  */

  position: relative;
}

@media screen and (min-width: 600px) {
  .auth-form-container {
    padding: 5rem;
    /* border: 1px solid  #c3def0; */
    border-radius: 10px;
    margin: 0.5rem;
  }
}

label {
  text-align: left;
  padding: 0.25rem 0;
}
/*   
  input {
    margin: 0.5rem 0;
    padding: 1rem;
    border: 1px solid  #c3def0;
    border-radius: 10px;
  } */

.login-button {
  border: none;
  background-color: #ff3200;
  padding: 20px;
  margin: 50px 0;

  border-radius: 10px;
  cursor: pointer;
  color: white;
  /* color: #7439db; */
  transition: all 0.15s ease-in-out;
}
.login-button:hover {
  transition: all 0.15s ease-in-out;
  background-color: #4c9818;
}
.link-btn {
  background: none;
  color: rgb(14, 47, 71);
  text-decoration: underline;
}

.anbessa-it-logo-login {
  width: 300px;
  padding: 50px 0;
}

:root {
  --primary: #c3def0;
}
.btn {
  box-shadow: 0px 1px 2px 0px rgba(128, 126, 126, 0.24);
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}
.primary-button {
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 12px;
  font-family: Inter;
  font-weight: 800;
  color: rgb(62, 62, 216);
  height: 10px;
  width: 100%;
  text-align: center;
  background-color: var(--primary);
  align-items: center;
  justify-content: center;
  /* border-radius: 6.626px;
  border: 1px solid rgba(0, 0, 0, 0.91); */
}
.primary-button:active {
  background-color: #a3cae4;

  /* border-radius: 6.626px;
  border: 1px solid rgba(0, 0, 0, 0.91); */
}

.btn:active {
  box-shadow: 0px 1px 2px 0px rgba(128, 126, 126, 0.24);
}
/* .btn--small {
    padding: 16px 35px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    font-weight: 800;
    font-family: Inter;
    font-size: 10px;
    
    height: 40px;
    width: 140px;
  }
  
  .btn--white {
    background-color: var(--primary);
    color: #166473;
    border: 1px solid #166473;;
    
  }
  .btn--dark {
    background-color: #166473;
    color: var(--primary);
    border: #166473;
  }
   */

.btn-1 {
  border-radius: 4px;
  /* outline: none; */
  /* border: none; */
  cursor: pointer;
  font-size: 12px;
  font-family: Inter;
  font-weight: 800;
  /* color: rgb(62, 62, 216); */
  height: 10px;
  width: 100%;
  text-align: center;
  background-color: red !important;
  align-items: center;
  justify-content: center;
}


.color-check{
  color: lightcoral;
}
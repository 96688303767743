.popup-container {
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(50, 50, 50, 0.5);
  overflow-x: hidden;
}

.cc{
  color: rgb(43, 43, 43);
  background-color: #6b6b6b;
}

.popup {
  /* width: 80%; */
  /* height: 80%; */
  border-radius: 0.3rem;
  background-color: white;
  padding: 2rem 3rem 1.5rem 3rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  transition: 100ms;
}
.title{
  padding: 2rem 3rem 0rem 3.4rem;

}
.popup-invisible{
  border-radius: 0.3rem;
  background-color: whitesmoke;
  padding: 2rem 3rem 1.5rem 3rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
   transform: translateX(100vw);
   overflow:hidden !important;
   transition: 100ms;
}

.popup .footer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-top: 1rem;
}
.popup .body {
  padding-bottom: 0.5rem;
  padding-top: 1.4rem;
}
/* .popup .body .form-body{
    display: flex;
    justify-content: space-evenly;
    gap: 3rem;
}
.popup .body form .form-body .text-field{
    display: flex;
    flex-direction: column;
} */

@media (max-width: 700px) {
  .popup-container {
    align-items: flex-start;
    justify-content: center;
  }
  .popup {
    /* border-radius: none; */
    background-color: whitesmoke;
    padding: 1rem 1rem 1rem 1rem;
    border-radius: 0rem;



    
  }
}
@media (max-width: 650px) {
  .popup-container {
    justify-content: stretch;


  }
}

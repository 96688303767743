
@media(max-width:800px){

    .--nomobileversion{
        display: flex !important;

    }
    


}


.--nomobileversion{
    display: none ;

    z-index: 100000;
    height: 100dvh;
    width: 100dvw;
    background-color: white;
    position: fixed;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
}

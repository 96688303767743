@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Proxima+Nova:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

* {
  padding: 0 0;
  margin: 0 0;
}
html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Inter", sans-serif;
}
.App {
  display: grid;
  grid-template-columns: minmax(17rem, 1fr) 4fr;
  grid-template-rows: 100vh;
}
.sidebar {
  background:#fff !important;
  /* background: darkblue !important; */
  /*  */
  display: flex;
  flex-direction: column;
  justify-content: start;
  border-right: 3px solid rgb(247, 246, 246) !important;
  overflow-y: hidden;

}
.mainBody {
  background-color: #fff;
  position: relative;
  height: 100%;
  overflow-y: hidden;
}

.no-style-link {
  text-decoration: none;
}

.mm {
  background: #FFFFFF; /* fallback for old browsers */
  /* background: -webkit-linear-gradient(
    to right,
    #9af2f5,
    #f7f8f8
  ); */
   /* Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(
    to right,
    #b9ebff,
    #f7f8f8
  );  */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: 100vh;
  width: 100%;
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.sectionTitle {
  padding: 20px 20px 3px 30px;
  font-family: Inter;
  font-size: 17px;
  font-style: italic;
  font-weight: 500;
}
.cursor {
  cursor: pointer;
}

@media (max-width: 700px) {
  
  .App {
    grid-template-columns: 1fr;
    grid-template-rows: 80px calc(100% - 80px) !important;
    height: 100%;
    background-color: transparent !important;
  }
  .mainBody {
    background-color: #fff;
    position: relative;
    height: 100%;
    overflow-y: hidden !important;
  }
}

.--flex-center-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.--fill-parent{
  width: 100%;
  height: 100%;
}

.--pointer{
  cursor: pointer;
}

.super-app-theme--header{
  font-weight: bolder;
  background-color:  rgba(184, 193, 196, 0.3);
  outline: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* .--red-color{
  color: red
} */

.maxWidth250{
  max-width: 250px;
}


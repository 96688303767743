.api-result{
    position: fixed;
    top: 20px;
    right: 20px;
    min-height: 5rem;
    width: 15rem;
    gap: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:1rem 2rem;
    border-radius: 0.6rem;
    background-color: aliceblue;
    border: 1px solid #c3def0;
    z-index: 10000;
    transition: 200ms;

}
.invisible{
    transform: translateX(200%) !important;
    overflow:hidden !important;
    transition: 300ms;
}

.msg{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}
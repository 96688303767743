.short-note{
    border-radius: 6px;
    background-color: lightblue;
    padding: 0.5rem .7rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    color: white;
    font-size: .8rem;
}


table,
th,
td {
  border: 1px solid lightblue !important;
  border-collapse: collapse;
  /* padding: 1rem; */
}

table {
  width: 100%;
  border: 1px solid #dddddd;
  margin: 0;
}

.pricing-table {
  overflow-x: auto;
  display: flex;
  justify-content: flex-start;
  /* border: 1px solid red; */
  align-items: center;
  padding-bottom: 1rem;
}

th,
td {
  padding: 1rem;
}
tr:nth-child(even) {
  /* background-color: #dddddd; */
}

h5 {
  /* padding: 1rem; */
}

.input-table:focus {
  outline: none !important;
  border: none;
  box-shadow: none;
}

.input-table {
  width: 200px;
  border: none;
}
.input-table-header:focus {
  outline: none !important;
  border: none;
  box-shadow: none;
}

.input-table-header {
  width: 200px;
  border: none;
  background-color: transparent;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

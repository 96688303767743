.main-body-str {
  overflow-y: hidden;
  height: 100vh;
  overflow-x: hidden;

}

.main-body-header {
  padding: 2rem 2rem 2rem 2rem;
  height: 7rem;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-bottom: 1px solid lightblue;

}

.main-body-header .buttons {
  display: flex;
  justify-content: flex-end;
}

.checkcolor {
  color: rgb(62, 62, 216);
}

.main-body-table {
  overflow-x: hidden;
  overflow-y: auto; 
  height: calc(100dvh - 7rem);
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 80%; */
} 
@media(max-width:700px){
  .main-body-table { 
    height:  calc(100vh - 80px);
  
  }
}

table {
  width: calc(100% - 8.1rem);
  padding: 2rem;
  margin: 2rem 2rem 0 2rem; 
 
  
}

.table {


  /* border-left: 1px solid lightblue;
  border-bottom: 1px solid lightblue;
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;
  background-color: rgb(228, 237, 240, 0.3); */
  margin: auto;
}
.table-image {
  width: 50px;
}

thead tr td {
  font-weight: 500;
}
table {
  border-collapse: collapse;
}
tr td {
  border-bottom: 1px solid lightblue;
  color: black;
}

/* table:last-child {
    border-bottom: none;
  } */

td {
  padding: 5px;
}

td p {
  font-size: 0.9rem;
}

.padding-left {
  padding-left: 1rem;
}

.paginator {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;
  padding: 1rem 4rem 2rem 0;
}


.data-grid {
  display: "flex"  !important;
  flex-direction: "column-reverse" !important
}
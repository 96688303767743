.item-card{
    width: 100%;
    height: 120px;
    border-radius: 10px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 5px rgba(182, 182, 182, 0.856) ;
    overflow: hidden;
    cursor: pointer;
}
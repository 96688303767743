.price-group{
    display: flex;
    gap: 10px;
    flex-direction: column;
    width: 100%;
}
.price{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    /* border: 1px solid red; */

}

.price p{
    background-color: white;
    float: left;
    z-index: 10;
    padding: 0 20px 0 0 ;
}


.filler {
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: 2px dashed #333333b9;
    height: 5%;
}

.textfield-list{
    background-color: white;
    z-index: 10;
    padding-left: 20px;
    display: flex;
}



/* input s */

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

input[type="file"] {
    display: none;
}

.upload-arrow-icon{
    padding: .4rem .5rem;
    color: white;
    background-color: #5C5F62;
    border-radius: 50%;
}
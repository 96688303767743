.nav-buttons-div{
  display: flex;
  justify-content: start; 
  
  gap: 20px; 
  padding: 20px 90px; 
  color: rgb(62, 62, 216);
  
  /* border-radius: 6.626px;
  border: 1px solid rgba(0, 0, 0, 0.91); */
}
 
.nav-buttons-div .text p{
  font-family: Inter;
  font-weight: 600;
  font-size: 1rem;
}

.nav-buttons-div:hover{
  background-color: rgba(220, 216, 230, 0.377);

}
.account-header{
  padding: 1rem 0;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-around;
}

.avatar{
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 1px solid transparent;
  background: rgb(226, 225, 225);
  overflow: hidden;
}
.avater-img{
  width: 100%;
  overflow: hidden;
}

.name-email{ 
  padding-left: 1rem;
  font-family: Inter;
  position: relative;
  right: 1.2rem;
}

.name-email p{
  font-size: 12px;

}

.twoOnePadding {
    border-top: 3px solid rgb(247, 246, 246);
    z-index: 0; 
  }

.anbessa-it-logo{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 7rem;
}
.anbessa-it-logo img{ 
  width: 50%;
}

.nav-bar-div{
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  justify-content: flex-start; 

}

.account-bar{
 justify-self: flex-end;
  height: 8rem;
  background-color:white;
  /* background-color: rgb(2, 2, 82); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* color: white; */
}


.logout-btn{
  border: 2px solid lightblue;
  display: flex;
  justify-content: center;
  margin: 4px 0;
  cursor: pointer;
  padding: 3px;
  z-index: 11;
}

.menu-buttons{
  overflow-y: auto;
  flex-grow: 1;
  list-style: none;
}

@media(max-width:700px){
  .anbessa-it-logo{
     
    width: 90px;
    height:100%;
  }

  .account-bar, .menu-buttons{
    display: none;
  }
  .nav-bar-div{
    justify-content: center;
    align-items: center;

  }
}
.add-new-company-form .form-body{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1.5rem;
    column-gap: 1rem;
    max-height: 60vh;
    overflow-y: auto;
    padding: .5rem 1rem 1rem .3rem;
    /* min-width: 40vw;
    max-width: 80vw; */
}

.text-field{
    display: flex;
    flex-direction: column;
}

#closing, #opening{
    padding-top: 10px;

}

.span-2{
    grid-column: span 2;
}

@media(max-width:700px){

    .add-new-company-form .form-body{
       
        grid-template-columns:1fr !important;
        
    }
    .span-2{
        grid-column: span 1;
    }
    



}
.add-new-company-form .form-body{
    display: grid;

    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.text-field{
    display: flex;
    flex-direction: column;
}

#closing, #opening{
    padding-top: 10px;

}

.span-2{
    grid-column: span 2;
}

@media(max-width:700px){

    .add-new-company-form .form-body{
       
        grid-template-columns:1fr !important;
        
    }
    .span-2{
        grid-column: span 1;
    }
    



}



.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

input[type="file"] {
    display: none;
}

.center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-field .value{
    align-self: flex-start;
    margin-left:1rem ;
}